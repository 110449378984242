@import "../../index.css";

.product-card {
  border: 0;
  overflow: hidden;
  display: block;
  box-shadow: 10px 10px 10px rgba(46, 54, 68, 0.05);
  margin-bottom: 10px;
  height: 100%;
}

.product-card_img-container {
  margin: 0px;
  padding: 0px 0px 100% 0px;
  overflow: hidden;
  position: relative;
}
.product-card_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.product-card_img:hover {
  transform: scale(1.1);
  transition: all 0.5s cubic-bezier(0, 0, 0.44, 1.18);
}

.product-card_body {
  position: relative;
  padding: 10px 8px 0px 0px;
}

.product-card_brand {
  font-size: 0.9em;
  color: var(--gray);
  line-height: 0.8;
  margin-bottom: 0;
}

.product-card_name {
  margin-top: 0.3rem;
  color: var(--secondary);
  font-size: 1.1em;
  font-weight: 500;
}

.product-card_color {
  font-size: 1.1em;
  color: var(--gray);
  line-height: 1.2;
  font-weight: 300;
  margin-top: 0rem;
}

.product-card_fibers {
  color: var(--gray);
  line-height: 1.2;
  font-size: 0.9em;
  font-weight: 300;
}

.product-card_price {
  color: var(--medium-gray);
  font-size: 1.2em;
  font-weight: bold;
  text-align: right;
  margin-top: -5px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.product-card_meterage {
  color: var(--gray);
  font-size: 0.8em;
  text-align: right;
  padding-top: 5px;
}

.product-card_badges {
  position: absolute;
  z-index: 200;
  top: 10px;
  left: 0;
}
.badge {
  margin: 0px;
  padding: 5px 10px;
}
.badge-secondary {
  background-color: var(--secondary);
}
