.product-list {
  width: 100%;
  /* table-layout: fixed; */
}

.product-list th {
  font-style: italic;
  font-size: 0.9em;
}

.product-list thead {
  border-bottom: 2px solid black;
  /* width: 100%; */
}

.product-list th,
.product-list td {
  /* height: 30px; */
  padding-right: 0 1rem;
  max-width: 80px;
}

.product-list-max {
  max-width: auto;
}

.product-list-item td {
  color: var(--primary);
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-right: 1px solid lightgray;
  font-size: 0.9em;
}

.product-list-item_image {
  max-width: 70px;
  width: 70px;
}
.product-list-image {
  width: 60px;
}
