@import "./cosmo.min.css";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Lato&display=swap");
/* @font-face {
  font-family: "Dancing Script";
  src: url("./Dancing_Script/static/DancingScript-Bold.ttf") format("ttf"), url("./Dancing_Script/static/DancingScript-Regular.ttf") format("ttf");
} */

/* --breakpoint-xs: 0;
--breakpoint-sm: 576px;
--breakpoint-md: 768px;
--breakpoint-lg: 992px;
--breakpoint-xl: 1200px; */

:root {
  --super-dark-gray: #202020;
  --dark-gray: #373942;
  --gray: #56556e;
  /* --medium-gray: #8d93ab;
  --medium-gray: #757a8f; */
  --medium-gray: #81869c;
  --light-gray: #e2e4e8;
  --super-light-gray: #f0f3f7;

  --super-dark-green: #032f04;
  --dark-green: #184d3d;
  --green: #4e861c;
  --light-green: #92d234;
  --super-light-green: #ccf111;

  --orange: orange;
  --red: #a44a55;
  --blue: #417d97;

  --primary: var(--blue);
  --secondary: var(--medium-gray);
  --danger: var(--red);
}

/* ----------------------- BACKGROUND COLORS ----------------------- */
.bg-super-dark-gray {
  background-color: var(--super-dark-gray);
  color: white;
}
.bg-dark-gray {
  background-color: var(--dark-gray);
  color: white;
}
.bg-gray {
  background-color: var(--gray);
  color: white;
}
.bg-medium-gray {
  background-color: var(--medium-gray);
  color: white;
}
.bg-light-gray {
  background-color: var(--light-gray);
}
.bg-super-light-gray {
  background-color: var(--super-light-gray);
}

.bg-super-dark-green {
  background-color: var(--super-dark-green);
  color: white;
}
.bg-dark-green {
  background-color: var(--dark-green);
  color: white;
}
.bg-green {
  background-color: var(--green) !important;
  color: white;
}
.bg-light-green {
  background-color: var(--light-green) !important;
}
.bg-super-light-green {
  background-color: var(--super-light-green);
}
.bg-orange {
  background-color: var(--orange);
  color: white;
}
.bg-red {
  background-color: var(--red);
  color: white;
}
.bg-blue {
  background-color: var(--blue) !important;
  color: white;
}

/* ----------------------- TEXT COLORS ----------------------- */
.text-super-dark-gray {
  color: var(--super-dark-gray);
}
.text-dark-gray {
  color: var(--dark-gray);
}
.text-gray {
  color: var(--gray);
}
.text-medium-gray {
  color: var(--medium-gray);
}
.text-light-gray {
  color: var(--light-gray);
}
.text-super-light-gray {
  color: var(--super-light-gray);
}
.text-super-dark-green {
  color: var(--super-dark-green);
}
.text-dark-green {
  color: var(--dark-green);
}
.text-green {
  color: var(--green) !important;
}
.text-light-green {
  color: var(--light-green) !important;
}
.text-super-light-green {
  color: var(--super-light-green);
}
.text-orange {
  color: var(--orange);
}
.text-red {
  color: var(--red);
}
.text-blue {
  color: var(--blue) !important;
}

.badge-danger {
  background-color: var(--red);
}

.badge-primary {
  background-color: var(--blue);
}

.alert-danger {
  background-color: #fce3e3;
}
.alert-warning {
  background-color: #ffe8be;
}

button[disabled] {
  border: none;
  background-color: var(--light-gray) !important;
  color: var(--medium-gray) !important;
}

.btn-success {
  color: #fff;
  background-color: var(--medium-gray);
  border-color: #d5dae5;
  outline: none !important;
}
.btn-success.bg-blue {
  background-color: var(--blue) !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success:active,
.btn-success.active {
  background-color: #757a8f;
  border-color: #757a8f;
  outline: none !important;
}
.btn-link {
  color: var(--primary);
}
.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  outline: none !important;
}
.btn,
.btn:hover,
.btn:active,
.btn:focus {
  outline: none !important;
}

.text-danger {
  color: var(--danger) !important;
}

a {
  color: var(--blue);
}
a:hover {
  color: #81869c;
}

.cursor-pointer {
  cursor: pointer;
}

.jumbotron {
  background-color: var(--super-light-gray);
}

html,
body,
#root {
  padding: 0;
  margin: 0;
}
header,
main {
  width: 100vw;
  max-width: 1700px;
  margin: auto;
}
main {
  min-height: calc(100vh - 570px);
}

@media screen and (max-width: 768px) {
  .jumbotron {
    padding: 1rem 1rem;
  }
  .footer-payments-image {
    width: 300px;
  }
}

@media screen and (max-width: 420px) {
  .footer-payments-image {
    width: 250px;
  }
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.rating i {
  color: var(--orange);
  font-size: 0.8em;
}

h1,
.h1 {
  font-family: "Dancing Script";
}

h1,
h2 {
  margin: 0.5em 0 0.5em 0;
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
  text-transform: capitalize;
  text-align: center;
}
h3 {
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 20px;
}
h5 {
  font-weight: 500;
}
.container {
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
}

td:first-of-type,
th:first-of-type {
  padding-left: 10px;
}
.order-summary-table th {
  font-size: 0.8em;
  font-weight: normal;
  font-style: oblique;
}

.link-primary {
  color: var(--primary);
  cursor: pointer;
}
.link-primary:hover {
  text-decoration: underline;
}

.carousel {
  height: 30vh;
  max-height: 30vh;
  overflow: hidden;
}
.carousel-caption {
  bottom: auto;
  top: 10px;
}

.promo-new-in-collection {
  margin: 40px 10% 10px 10%;
}

.product-card-block {
  padding: 15px;
}

.card-body {
  padding: 0 0 10px 0;
}
.card-title {
  margin-top: 0.75rem;
  margin-bottom: 0;
}

.table thead th {
  border: 0;
}

.color-picture {
  display: inline-block;
  flex: 1 1 auto;
  margin: 3px;
}

.order-select {
  min-width: 120px;
}

.list-group-item p {
  margin-bottom: 0.5rem;
}

.col {
  padding-right: 0;
}

.img-example {
  width: 60%;
  height: 60%;
  margin: 20%;
  filter: opacity(0.3);
}

.product-brand {
  text-align: center;
  margin-bottom: -15px;
}

.submenu {
  text-align: right;
}

.breadcrumb-item .nav-item .nav-link {
  padding: 0 0.6rem;
}

/* ------------------- FORMS --------------------- */
.form-inline {
  display: flex;
  flex-flow: row nowrap;
}
.form-container {
  justify-content: center;
}
.form-group {
  margin-top: 1.2rem;
  margin-bottom: 0;
}
label[required]::after {
  content: "*";
  color: red;
  font-size: 1.6em;
  line-height: 0.1;
}
label {
  margin-bottom: 0;
  cursor: pointer;
}
.label-comment {
  font-size: 0.9em;
  color: var(--mediun-gray);
  line-height: 0.9em;
}
.form-check-input {
  margin-top: 1em;
}
.checkboxImg {
  margin-top: -25px;
}
.custom-checkbox input[type="checkbox"] {
  visibility: hidden;
}
.custom-checkbox input[type="checkbox"] + label:before {
  border: 1px solid #333;
  border-radius: 3px;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 15px;
  margin: 0.2em 0.5em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
}
.custom-checkbox input[type="checkbox"]:checked + label:before {
  background: #fff;
  color: #333;
  content: "\2713";
  text-align: center;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}
.custom-checkbox input[type="checkbox"]:focus + label::before {
  outline: var(--super-light-green) auto 5px;
}
.custom-control {
  padding-left: 0;
}
.form-control {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background-color: #9e9e9e0f !important;
  min-width: 120px;
}

.list-group-item {
  padding: 0.25rem 1.25rem;
}

.form-control {
  height: calc(1.5em + 0.25rem + 2px);
  padding: 0.25rem 0.75rem;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--green);
}

input[type="checkbox"],
input[type="radio"] {
  margin-top: 5px;
}

.text-desabled {
  text-decoration: line-through;
  font-weight: 300;
  font-style: italic;
}
