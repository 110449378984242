.hide-container {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(66, 70, 82, 0.8);
}
