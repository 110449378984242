/* ------- LOGO ------- */
.logo {
  height: 70px;
  margin-left: 10px;
}
@media (max-width: 992px) {
  .logo {
    height: 60px;
  }
}
@media (max-width: 768px) {
  .logo {
    height: 50px;
  }
}
@media (max-width: 992px) {
  #admin-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
}

.nav-link {
  padding: 0.5rem 0.5rem;
}
/* ------- ADMIN MENU ------- */
.admin-menu-container {
  position: relative;
}
.admin-menu {
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding: 0;
  position: absolute;
  top: 0px;
  right: 0px;
}
.admin-menu a {
  margin: 0 5px;
  padding: 0 18px;
  background-color: var(--medium-gray);
  color: white;
  /* border-radius: 0 0 5px 5px; */
}
.admin-menu a:hover {
  background-color: var(--gray);
}

/* ------- INFO MENU ------- */
.navbar .container {
  justify-content: flex-end;
}
.info-menu-container {
  margin-top: 20px;
}
.navbar-toggler,
.navbar-toggler:hover,
.navbar-toggler:focus,
.navbar-toggler:active {
  padding: 0.2rem 0.3rem;
  border-radius: 20px;
  border: 3px solid var(--light-gray);
  outline: none;
}

/* ------- CATEGORY MENU ------- */
.nav-link.underlink {
  padding: 0 10px 0 10px;
}
.underlink {
  position: relative;
  color: var(--dark-gray);
  text-decoration: none;
}
.underlink-content {
  display: block;
  position: relative;
  z-index: 2;
}
.underlink:hover {
  color: var(--super-dark-gray);
}
.underlink::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 8px;
  bottom: 0px;
  left: auto;
  z-index: 1;
  background-color: var(--super-light-green);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.underlink:hover::before,
.underlink.active::before {
  visibility: visible;
  transform: scaleX(1);
  z-index: 1;
}
