#product-details {
  max-width: 100vw;
  display: grid;
  gap: 0;
  grid-auto-rows: max-content;
}

#product-description,
#product-add,
#product-title {
  padding: 2em 0px;
}

@media screen and (max-width: 600px) {
  #product-details {
    grid-template-columns: 6fr 6fr;
  }
  #product-gallery {
    grid-column: 1/3;
    grid-row: 1;
  }
  #product-title {
    grid-column: 1/3;
    grid-row: 2;
    margin-top: 5px;
  }
  #product-add {
    grid-column: 1/3;
    grid-row: 3;
  }
  #product-description {
    grid-column: 1/3;
    grid-row: 4;
  }
  #product-write-review {
    grid-column: 1/3;
    grid-row: 6;
  }
  #product-reviews {
    grid-column: 1/3;
    grid-row: 5;
  }
}

@media screen and (min-width: 600px) {
  #product-details {
    grid-template-columns: 6fr 6fr;
  }
  #product-gallery {
    grid-column: 1 / 3;
    grid-row: 1;
    margin: 0 2em 2em 0;
  }
  #product-title {
    grid-column: 1;
    grid-row: 2;
  }
  #product-add {
    grid-column: 2;
    grid-row: 2;
  }
  #product-description {
    grid-column: 1/3;
    grid-row: 3;
  }
  #product-write-review {
    grid-column: 2;
    grid-row: 4;
  }
  #product-reviews {
    grid-column: 1;
    grid-row: 4;
  }
}

@media screen and (min-width: 768px) {
  #product-details {
    grid-template-columns: 4fr 4fr 4fr;
  }
  #product-gallery {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
  #product-title {
    grid-column: 3;
    grid-row: 1;
  }
  #product-add {
    grid-column: 3;
    grid-row: 2;
  }
  #product-description {
    grid-column: 2/4;
    grid-row: 3/5;
  }
  #product-write-review {
    grid-column: 1;
    grid-row: 4;
  }
  #product-reviews {
    grid-column: 1;
    grid-row: 3;
  }
}

@media screen and (min-width: 992px) {
  #product-details {
    grid-template-columns: 3fr 3fr 3fr 3fr;
    grid-auto-rows: minmax(100px, auto);
  }
  #product-gallery {
    grid-column: 1/3;
    grid-row: 1 / 3;
  }
  #product-title {
    grid-column: 3;
    grid-row: 1;
  }
  #product-add {
    grid-column: 4;
    grid-row: 1;
  }
  #product-description {
    grid-column: 3/5;
    grid-row: 2/4;
  }
  #product-write-review {
    grid-column: 2;
    grid-row: 3;
  }
  #product-reviews {
    grid-column: 1;
    grid-row: 3;
  }
}
.list-group-item p {
  margin-bottom: 0;
}
.product_color {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid var(--light-gray);
}

/* image hallery */
button.image-gallery-icon {
  filter: none;
}
.image-gallery-right-nav,
.image-gallery-left-nav {
  transform: translateY(-50%) scale(0.5);
  padding: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 100px;
  width: 100px;
}
.image-gallery-fullscreen-button {
  width: 75px;
  height: 75px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  left: 15px;
  bottom: 15px;
}
.image-gallery-svg.active,
.image-gallery-svg:hover,
.image-gallery-svg:focus {
  color: var(--light-green);
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 36px;
    width: 36px;
  }
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid var(--light-green);
}
