.display-none,
.display-block {
  transition: all 1s ease;
}
.display-flex {
  display: flex;
  justify-content: space-evenly;
}
.display-none {
  display: none;
}
.display-block {
  display: block;
}

.filtered-products-container {
  width: 100%;
}

.filter-container {
  width: 228px;
  min-width: 228px;
  max-width: 228px;
}
@media (max-width: 768px) {
  .filter-container {
    width: 180px;
    min-width: 180px;
    max-width: 180px;
  }
}
@media (max-width: 576px) {
  .filter-container {
    display: none;
  }
}

.filter {
  min-width: 166px;
  margin: 0 2px 0 5px;
}

.filter label {
  margin-top: 0.2rem;
  font-size: 0.8rem;
  white-space: nowrap;
}
.filter .form-check-input {
  margin-top: 0.5em;
}

.filter .label-comment {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.filter .label-comment div {
  padding: 0;
  margin: 0;
}

.color {
  margin: 0px -5px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.color div {
  box-sizing: content-box;
  margin: 3px;
  width: 25px;
  height: 25px;
  position: relative;
  padding: 0;
}

.color [type="checkbox"]:not(:checked),
.color [type="checkbox"]:checked {
  box-sizing: content-box;
  position: absolute;
  opacity: 0.01;
}

.color label {
  box-sizing: content-box;
  position: absolute;

  display: block;
  width: 25px;
  height: 25px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  cursor: pointer;
  z-index: 100;
}

/* ----------------- Colors Section ------------------- */
.color div label:before {
  box-sizing: content-box;
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid var(--light-gray);
  /* opacity: 0.8; */
}
.product_color.bg-white,
.color > div.white label:before {
  background: white;
}
.product_color.bg-natural,
.color > div.natural label:before {
  background: #fbf8e0;
  border: 1px solid #e6e3ca;
}
.product_color.bg-beige,
.color > div.beige label:before {
  background: #c7b299;
}
.product_color.bg-yellow,
.color > div.yellow label:before {
  background: #ffe168;
}
.product_color.bg-orange,
.color > div.orange label:before {
  background: #f2763d;
}
.product_color.bg-red,
.color > div.red label:before {
  background: #d92b31;
}
.product_color.bg-pink,
.color > div.pink label:before {
  background: #f06eaa;
}
.product_color.bg-purple,
.color > div.purple label:before {
  background: #9f5ddd;
}
.product_color.bg-black,
.color > div.black label:before {
  background: black;
}
.product_color.bg-grey,
.color > div.grey label:before {
  background: grey;
}
.product_color.bg-green,
.color > div.green label:before {
  background: green;
}
.product_color.bg-blue,
.color > div.blue label:before {
  background: #2780e3 !important;
}
.product_color.bg-brown,
.color > div.brown label:before {
  background: rgb(158, 63, 19);
}
.product_color.bg-multicolor,
.color > div.multicolor label:before {
  /* background: linear-gradient(45deg, #fffc00, red 60%, #2780e3); */
  /* background: linear-gradient(45deg, white 25%, red 25%, red 45%, #2780e3 45%, #2780e3 65%, #fffc00 65%, #fffc00 100%); */
  background: linear-gradient(45deg, white, red, orange, yellow, var(--light-green), rgb(0, 204, 255), var(--blue), purple);
}

.color [type="checkbox"]:checked + label:before,
.color [type="checkbox"]:hover + label:before {
  box-sizing: content-box;
  border-radius: 50%;
  opacity: 1;
}

.color [type="checkbox"]:checked + label::after,
.color [type="checkbox"]:hover + label::after {
  box-sizing: content-box;
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 25px;
  height: 25px;
  border: 3px solid var(--light-green);
  border-radius: 50%;
}

/* ----------------- Range ------------------- */
input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: var(--light-gray);
  border-radius: 1.3px;
}
input[type="range"]::-webkit-slider-thumb {
  border: 1px solid var(--medium-gray);
  height: 20px;
  width: 12px;
  border-radius: 3px;
  background: var(--super-light-green);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--medium-gray);
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: var(--light-gray);
  border-radius: 1.3px;
}
input[type="range"]::-moz-range-thumb {
  border: 1px solid var(--medium-gray);
  height: 20px;
  width: 12px;
  border-radius: 3px;
  background: var(--super-light-green);
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #2a6495;
  border-radius: 2.6px;
}
input[type="range"]::-ms-fill-upper {
  background: var(--light-gray);
  border-radius: 2.6px;
}
input[type="range"]::-ms-thumb {
  border: 1px solid var(--medium-gray);
  height: 20px;
  width: 12px;
  border-radius: 3px;
  background: var(--super-light-green);
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: var(--light-gray);
}
input[type="range"]:focus::-ms-fill-upper {
  background: var(--medium-gray);
}
